@import "../_loader.scss";

body.page--login, body.page--login-form, body.page--fakelogin {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $color-white;

  background: url("#{$path-images}/bg-full-login@2x.jpg") no-repeat center center;
  background-size: cover;

  header, footer {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
  }

  header {
    padding-left: 3rem;
    padding-top: 2rem;
  }

  footer {
    top: auto;
    bottom: 0;
  }

  .admin-panel {
    background: $color-white;
    max-width: 480px;
    width: 80%;
    border-radius: 12px;
    padding: 6rem 8rem;
    margin: 0 auto;
    box-shadow: 0 6px 14px 0 rgba(black, .3);

    .admin-panel__header {
      color: $color-primary;
      font-size: 4.2rem;
      font-weight: $font-light;
      line-height: 1.02;
      padding-bottom: 4rem;

      strong {
        font-weight: $font-semibold;
      }
    }

    .admin-panel__content {
      .form-group {
        margin-bottom: 2rem;
      }
    }
  }

  .help-block {
    text-align: center;
  }
}
